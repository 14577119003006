import React from 'react';
import { Layout as AntdLayout } from 'antd';
import { Layout, SectionHeader, SectionAppFeatures, SectionFooter, Footer } from '../components';

class IndexPage extends React.Component {
  wow: any;

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const WOW = require('wowjs');
      this.wow = new WOW.WOW();
      this.wow.init();
    }
  }

  componentDidUpdate() {
    if (typeof window !== 'undefined') {
      this.wow.sync();
    }
  }

  render() {
    return (
      <Layout>
        <AntdLayout.Content>
          <SectionHeader />
          <SectionAppFeatures />
          <SectionFooter />
        </AntdLayout.Content>
        <Footer />
      </Layout>
    );
  }
}

export default IndexPage;
